/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Macondo&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet');
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');


@import url(https://fonts.googleapis.com/earlyaccess/cwtexyen.css);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button, input {
	/* margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%; */
	/* font: inherit; */
  /* font-family: 'cwTeXYen', sans-serif; */
  font-family: 'Indie Flower','Rancho', 'Macondo', 'cwTeXYen', cursive;
	vertical-align: baseline;
  font-size:20px ;
}
button:hover{
  background: #A5CAD3;
}

div::-webkit-scrollbar{
  width: 8px;
  border-radius: 200p;
}
div::-webkit-scrollbar-track {
  /* background-color: #f8fafa; */
  border-radius: 100px;
}
div::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #D0368A 0%, #708AD4 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 200px;
}


.frame_border {
  border-style: solid;
  border-width: 4px;
  border-color: black;
  border-radius: 20px;
  /* box-shadow: -3px 2px 9px 6px blue; */
}

.colorChange_border {
  border-style: solid;
  border-width: 4px;
  border-color: black;
  border-radius: 20px;
  animation:bordermove 4s infinite; /*animation 时间 播放次数*/  
  animation-direction:alternate; /*是否循環交替反向播放*/ 
  -webkit-animation:bordermove 3s infinite;/*Safari 和 Chrome:*/
}
@keyframes bordermove  {
  0% {border-color:#87CEFF;}
  0%{box-shadow: -3px 2px 9px 6px rgb(78, 78, 236)}  
  25% {border-color:#54FF9F;}
  35%{box-shadow: -3px 2px 9px 6px rgb(46, 46, 249)}
  50% {border-color:#7CCD7C;} 
  60%{box-shadow: -3px 2px 9px 6px #36f736}
  75% {border-color:#63B8FF;} 
  85%{box-shadow: -3px 2px 9px 6px rgb(66, 66, 218)}
  100% {border-color:#00FFFF;}
  100%{box-shadow: -3px 2px 9px 6px #35c1c1} 
}  
 
	/*Safari 和 Chrome:*/
@-webkit-keyframes bordermove{
  0% {border-color:#87CEFF;}
  25% {border-color:#54FF9F;}
  50% {border-color:#7CCD7C;}
  75% {border-color:#63B8FF;}
  100% {border-color:#00FFFF;}
}

.component_border {
  border-style: solid;
  border-width: 3px;
  border-color: black;
  border-radius: 10px;
  /* box-shadow: -3px 2px 9px 6px blue; */
}

.answer_input {
  border-style: solid;
  border-width: 3px;
  /* border-color: black; */
  border-radius: 10px;
}

.answer_input::before {
  /* content:url('./img/happy.gif');
  width:  10px;
  height: 10px; */
}

